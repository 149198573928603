import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField, { TextFieldProps } from '@mui/material/TextField';
// @ts-ignore
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// @ts-ignore
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
// @ts-ignore
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import s from './MyDatePicker.module.css'

export default function MaterialUIPickers() {
    const [value, setValue] = React.useState<Date | null>(
        new Date('2014-08-18T21:11:54'),
    );

    const handleChange = (newValue: Date | null) => {
        setValue(newValue);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3} width={180} height={48}>
                <DesktopDatePicker className={s.date_picker}
                    label=""
                    inputFormat="MM/dd/yyyy"
                    value={value}
                    onChange={handleChange}
                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} className={s.field_style} />}
                />
            </Stack>
        </LocalizationProvider>
    );
}
