import {useMemo} from "react";

export const useSortedAccounts = (userAccountsList: any, selectedCurrency: any) => {
    const sortedAccounts = useMemo(() => {

        if (selectedCurrency === "ALL") {
            return userAccountsList
        }

        const filteredList = userAccountsList.filter((account: any) => {
            return account.currency === selectedCurrency
        })

        return filteredList

    }, [userAccountsList, selectedCurrency])

    return sortedAccounts
}
