import React from 'react';
import {Layout, Space} from 'antd';
import s from './PersonalAccount.module.css'
import logo from '../../assets/img/logo_personal_account.png'
import arrDown from '../../assets/img/chevron_down.png'
import chat from '../../assets/img/chat_icon.png'
import {NavLink, Outlet, useNavigate} from 'react-router-dom';
import './index.css'
import classnames from "classnames";
import {
    CHANGE_PASSWORD,
    DETAILS,
    EXCHANGE,
    INTRA_TRANSFERS,
    PROFILE_INFO,
    STATEMENT,
    TRANSFERS
} from "../../router/Constants";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {LogoutThunk} from "../../store/reducers/ActionCreators";
import {userDataType} from "../../store/reducers/UserSlice";
import MyPopupTransactionSuccess from "../../components/ui/MyPopupTransactionSuccess/MyPopupTransactionSuccess";
import MyPopupTransactionRejected from "../../components/ui/MyPopupTransactionRejected/MyPopupTransactionRejected";

const {Header, Sider, Content} = Layout;

const headerStyle: React.CSSProperties = {
    color: '#fff',
    height: 64,
    lineHeight: '64px',
    backgroundColor: '#fff',
    margin: '14px 44px 0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 24px'
};

const contentStyle: React.CSSProperties = {
    minHeight: '92vh',
    color: '#000',
    backgroundColor: '#ECECEC',
    padding: '72px 44px 0'
};

const siderStyle: React.CSSProperties = {
    position: 'relative',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#131313',
    flex: '0 0 316px',
    zIndex: 1,
};

const PersonalAccount = (props: any) => {

    const userData:userDataType = useAppSelector( state => state.userReducer.user.userData)
    const isTransactionWasSendSuccess: boolean | null = useAppSelector( state => state.userReducer.transactionWasSendSuccess)

    const navigate = useNavigate();

    const dispatch = useAppDispatch()

    const handleLogout = () => {
        dispatch(LogoutThunk())
            .then(() => {
                navigate("/");
            })
    }

    const isShowSuccessPopup = () => {
        if (isTransactionWasSendSuccess) {
            return <MyPopupTransactionSuccess/>
        }
    }

    const isShowRejectPopup = () => {
        if (isTransactionWasSendSuccess === false) {
            return <MyPopupTransactionRejected/>
        }
    }

    return (
        <div>

            { isShowSuccessPopup() }
            { isShowRejectPopup() }

            <Space direction="vertical" style={{width: '100%', position: "relative"}} size={[0, 0]}>
                <Layout>
                    <Sider style={siderStyle} width={316}>
                        <img src={logo} alt="" className={s.logo}/>

                        <NavLink to={DETAILS}
                                 className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                            <div className="img_block">
                                <div className="img_details"></div>
                            </div>
                            <div className="text_block">
                                Accounts Details
                            </div>
                        </NavLink>

                        <NavLink to={STATEMENT}
                                 className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                            <div className="img_block">
                                <div className="img_statements"></div>
                            </div>
                            <div className="text_block">
                                Statements
                            </div>
                        </NavLink>
                        <NavLink to={TRANSFERS}
                                 className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                            <div className="img_block">
                                <div className="img_transfers"></div>
                            </div>
                            <div className="text_block">
                                Transfers
                            </div>
                        </NavLink>
                        <NavLink to={INTRA_TRANSFERS}
                                 className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                            <div className="img_block">
                                <div className="img_intra"></div>
                            </div>
                            <div className="text_block">
                                Intra Transfers
                            </div>
                        </NavLink>
                        <NavLink to={EXCHANGE}
                                 className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                            <div className="img_block">
                                <div className="img_exchange"></div>
                            </div>
                            <div className="text_block">
                                Currency Exchange
                            </div>
                        </NavLink>
                        <div className={s.static_column}></div>
                        <div className={s.all_rights}>
                            <div>© 2023 CG Bank.</div>
                            <div className={s.yellow}>All rights reserved.</div>
                        </div>
                    </Sider>
                    <Layout>
                        <div className={s.header_wrapper}>
                            <Header style={headerStyle}>
                                <div className={s.left}>
                                    <div className={s.dropdown_language}>
                                        <span>EN</span>
                                        <img src={arrDown} alt=""/>
                                    </div>
                                    <div className={s.welcome_block}>
                                        Welcome, <span className={s.caps}>{userData.salutation} {userData.firstName} {userData.lastName}</span>
                                    </div>
                                </div>
                                <div className={s.right}>
                                    <div className={s.chat_icon}>
                                        <img src={chat} alt=""/>
                                    </div>
                                    <div className={s.btn_block}>
                                        <NavLink to={PROFILE_INFO}>
                                            <div className={s.btn_header}>
                                                My Profile
                                            </div>
                                        </NavLink>
                                        <NavLink to={CHANGE_PASSWORD}>
                                            <div className={s.btn_header}>
                                                Change Password
                                            </div>
                                        </NavLink>
                                        <div className={s.btn_header} onClick={handleLogout}>
                                            Sign Out
                                        </div>
                                    </div>
                                </div>
                            </Header>
                        </div>
                        <Content style={contentStyle}>
                            <Outlet/>
                        </Content>
                    </Layout>
                </Layout>
            </Space>
        </div>
    );
};

export default PersonalAccount;
