import React, {useEffect} from 'react';
import s from './Statement.module.css'
import SelectSmall from "../../../components/ui/MySelect/MySelect";
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import MaterialUIPickers from "../../../components/ui/MyDatePicker/MyDatePicker";
import {useAccountsForSelect} from "../../../hooks/useAccountsForSelect";
import MySelectAccounts from "../../../components/ui/MySelectAccounts/MySelectAccounts";
import {accountsArrayType} from "../../../store/reducers/UserSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {GetTransactionsListThunk} from "../../../store/reducers/ActionCreators";
import approved from "../../../assets/img/approved.png"
import pending from "../../../assets/img/pending.png"
import denied from "../../../assets/img/denied.png"

const Statement = () => {

    const dispatch = useAppDispatch()

    const userToken: string = useAppSelector( state => state.userReducer.user.userToken)
    const transactionsList: any = useAppSelector( state => state.userReducer.userTransactions)
    const accountsForSelect = useAccountsForSelect()
    const userAccountsList:accountsArrayType[] = useAppSelector( state => state.userReducer.userAccounts)

    const getAccountsListCurrency = () => {
        return userAccountsList?.map((acc) => {
            return acc.currency
        })
    }

    const currencyArr = getAccountsListCurrency()
    currencyArr.push("ALL")

    useEffect(() => {
        dispatch(GetTransactionsListThunk(userToken))
    }, [])

    const getImgForTransactionStatus = (transactionStatus: string) => {
        if ( transactionStatus === "Approved" ) {
            return <img src={approved} alt=""/>
        }

        if ( transactionStatus === "Denied" ) {
            return <img src={denied} alt=""/>
        }

        if ( transactionStatus === "New" ) {
            return <img src={pending} alt=""/>
        }
    }

    const transactionDirectionIsOut = (direction: string) => {
        if (direction === "OUT") {
            return true
        }
    }

    const ConvertDate = (timestamp: any) => {
        let dateFormat = new Date(timestamp * 1000);

        return dateFormat.getDate() +
            "/" + (dateFormat.getMonth() + 1) +
            "/" + dateFormat.getFullYear()
    }

    return (
        <div className={s.wrapper}>
            <div className={s.statement_filters_wrapper}>
                <div className={s.filter_block}>
                    <div className={s.filter_title}>
                        Account Number
                    </div>
                    <MySelectAccounts item={accountsForSelect} width={230} height={48}/>
                </div>
                <div className={s.filter_block}>
                    <div className={s.filter_title}>
                        From Date
                    </div>
                    <MaterialUIPickers/>
                </div>
                <div className={s.filter_block}>
                    <div className={s.filter_title}>
                        To Date
                    </div>
                    <MaterialUIPickers/>
                </div>
                <div className={s.filter_block}>
                    <div className={s.filter_title}>
                        Currency
                    </div>
                    <SelectSmall item={currencyArr} width={80} height={48}/>
                </div>
                <MyBtn title={"To Apply"} onClick={() => {}}/>
            </div>
            <div className={s.statement_table}>
                <div className={s.table_header}>
                    <div className={s.header_item_short}>Date</div>
                    <div className={s.header_item_short}>Transfer Type</div>
                    <div className={s.header_item_long}>Sender</div>
                    <div className={s.header_item_long}>Beneficiary</div>
                    <div className={s.header_item_long}>Payment</div>
                    <div className={s.header_item_long}>Transaction number</div>
                    <div className={s.header_item_short}>
                        Status
                    </div>
                    <div className={s.header_item_short}>Amount</div>
                    <div className={s.header_item_short}>Balance</div>
                </div>
                {
                    transactionsList.map((transaction: any) => <div className={s.table_row} key={transaction.transactionNumber}>

                        <div className={s.header_item_short}>{ConvertDate(transaction.created)}</div>
                        <div className={s.header_item_short}>{transaction.transactionType}</div>
                        <div className={s.header_item_long}>{transaction.fromAccount}</div>
                        <div className={s.header_item_long}>
                            {transaction.toAccount}
                        </div>
                        <div className={s.header_item_long}>{transaction.reference}</div>
                        <div className={s.header_item_long}>{transaction.transactionNumber}</div>
                        <div className={s.header_item_short}>{getImgForTransactionStatus(transaction.transactionStatus)}</div>
                        <div className={s.header_item_short}>
                            { transactionDirectionIsOut(transaction.direction)
                                ?  <span className={s.out_style}> - {transaction.amount} {transaction.currency}</span>
                                :  <span className={s.in_style}> + {transaction.amount} {transaction.currency}</span>
                            }
                        </div>
                        <div className={s.header_item_short}>{transaction.balance}</div>
                    </div>)
                    // <div className={s.without_transactions}>Your account hasn't got any transactions</div>
                }
            </div>
        </div>
    );
};

export default Statement;
