import React from 'react';
import s from './MyProfile.module.css'
import {userDataType} from "../../../store/reducers/UserSlice";
import {useAppSelector} from "../../../hooks/redux";

const MyProfile = () => {

    const userData:userDataType = useAppSelector( state => state.userReducer.user.userData)

    return (
        <div>
            <div className={s.title}>
                Personal Information
            </div>
            <div className={s.info_wrapper}>
                <div className={s.block}>
                    <div className={s.row}>
                        <div className={s.field_name}>Client ID:</div>
                        <div className={s.field_value}>{userData.clientId ? userData.clientId : "-"}</div>
                    </div>
                    <div className={s.row}>
                        <div className={s.field_name}>Client Name:</div>
                        <div className={s.field_value}>{userData.firstName ? userData.firstName : "-"}</div>
                    </div>
                    <div className={s.row}>
                        <div className={s.field_name}>Company Name:</div>
                        <div className={s.field_value}>{userData.companyName ? userData.companyName : "-"}</div>
                    </div>
                    <div className={s.row}>
                        <div className={s.field_name}>Address:</div>
                        <div className={s.field_value}>{userData.address ? userData.address : "-"}</div>
                    </div>
                    <div className={s.row}>
                        <div className={s.field_name}>State/Province:</div>
                        <div className={s.field_value}>{userData.stateOrProvince ? userData.stateOrProvince : "-"}</div>
                    </div>
                    <div className={s.row}>
                        <div className={s.field_name}>City:</div>
                        <div className={s.field_value}>{userData.city ? userData.city : "-"}</div>
                    </div>
                </div>
                <div className={s.block}>
                    <div className={s.row}>
                        <div className={s.field_name}>Country:</div>
                        <div className={s.field_value}>{userData.country ? userData.country : "-"}</div>
                    </div>
                    <div className={s.row}>
                        <div className={s.field_name}>Telephone:</div>
                        <div className={s.field_value}>{userData.callPhone ? userData.callPhone : "-"}</div>
                    </div>
                    <div className={s.row}>
                        <div className={s.field_name}>Fax:</div>
                        <div className={s.field_value}>{userData.fax ? userData.fax : "-"}</div>
                    </div>
                    <div className={s.row}>
                        <div className={s.field_name}>E-mail:</div>
                        <div className={s.field_value}>{userData.email ? userData.email : "-"}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyProfile;
