import React, {useEffect, useState} from 'react';
import s from './SignUpOrSignIn.module.css'
import logo from '../../assets/img/logo.png'
import MyBtn from "../../components/ui/MyBtn/MyBtn";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {LoginThunk} from "../../store/reducers/ActionCreators";
import {NavLink, useNavigate} from "react-router-dom";
import {DETAILS} from "../../router/Constants";

const SignUpOrSignIn = () => {

    const authStatus = useAppSelector(state => state.userReducer.error)

    const navigate = useNavigate();
    const dispatch = useAppDispatch()

    const [loginData, setLoginData] = useState({
        login: "",
        password: ""
    })
    const[error, setError] = useState(false)

    useEffect(() => {
        if (authStatus === 200) {
            navigate(DETAILS)
        }

        if (authStatus === 403) {
            setError(true)
        }
    }, [authStatus])

    const handleLogin = async () => {
        await dispatch(LoginThunk(loginData))
    }

    return (
        <div className={s.login_wrapper_content_left}>
            <div className={s.logo_block}>
                <NavLink to={"https://cgbanque.com/index.html"}>
                    <img src={logo} alt=""/>
                </NavLink>

            </div>
            <div className={s.sign_in_wrapper}>
                <div className={s.sign_in_content}>
                    <div className={s.title}>
                        Sign In
                    </div>
                    <div className={s.subtitle}>
                        Sign In and enjoy all the features of CG Bank.
                    </div>
                    <input type="text"
                           className={s.my_input}
                           placeholder={"Login"}
                           onChange={(e) => {
                               setLoginData(
                                   {...loginData, login: e.currentTarget.value}
                               )
                           }}
                    />
                    <input type="text"
                           className={s.my_input}
                           placeholder={"Password"}
                           onChange={(e) => {
                               setLoginData(
                                   {...loginData, password: e.currentTarget.value}
                               )
                           }}
                    />
                    {error
                        ? <div className={s.error}>invalid login or password</div>
                        : <></>
                    }
                    <div className={s.btn_block}>
                        <MyBtn title={"Sign In"} onClick={handleLogin}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpOrSignIn;
