import React from 'react';
import s from './MyBtn.module.css'

type PropsType = {
    title: string,
    onClick?: () => void;
}

const MyBtn:React.FC<PropsType> = ({title, ...props}) => {
    return (
        <button className={s.my_btn} {...props}>
            {title}
        </button>
    );
};

export default MyBtn;
