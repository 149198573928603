import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import s from './MySelectAccounts.module.css'

type propsType = {
    item?: (string | null)[]
    width: number
    height: number
    onAccountSelectHandler?: (number: string, currency: string) => void
    selectedItem?: string
}

export default function MySelectAccounts(props: propsType) {

    const handleClick = (event: any) => {
        props.onAccountSelectHandler?.(event.target.textContent, event.target.dataset.currency);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: props.width, height: props.height }}  size="small" className={s.my_select_wrapper}>
            <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={props.selectedItem}
                className={s.my_select}
            >
                {
                    props.item?.map((i: any) => <MenuItem onClick={handleClick} key={i.number} data-currency={i.currency} value={i.number}>{i.number}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}
