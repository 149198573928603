import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import SignUpOrSignIn from "./pages/SignUpOrSignIn/SignUpOrSignIn";
import {
    CHANGE_PASSWORD,
    EXCHANGE,
    INTRA_TRANSFERS,
    PERSONAL_ACCOUNT,
    PROFILE_INFO,
    STATEMENT,
    TRANSFERS
} from "./router/Constants";
import PersonalAccount from "./pages/PersonalAccount/PersonalAccount";
import Details from "./pages/PersonalAccount/Details/Details";
import Statement from "./pages/PersonalAccount/Statement/Statement";
import Transfers from "./pages/PersonalAccount/Transfers/Transfers";
import IntraTransfers from "./pages/PersonalAccount/IntraTransfers/IntraTransfers";
import Exchange from "./pages/PersonalAccount/Exchange/Exchange";
import MyProfile from "./pages/PersonalAccount/MyProfile/MyProfile";
import ChangePassword from "./pages/PersonalAccount/ChangePassword/ChangePassword";

function App() {
  return (
    <div className="App">
        <Routes>
            <Route index element={<SignUpOrSignIn/>}/>
            <Route path={PERSONAL_ACCOUNT} element={<PersonalAccount/>}>
                <Route index element={<Details/>}/>
                <Route path={STATEMENT} element={<Statement/>}/>
                <Route path={TRANSFERS} element={<Transfers/>}/>
                <Route path={INTRA_TRANSFERS} element={<IntraTransfers/>}/>
                <Route path={EXCHANGE} element={<Exchange/>}/>
                <Route path={PROFILE_INFO} element={<MyProfile/>}/>
                <Route path={CHANGE_PASSWORD} element={<ChangePassword/>}/>
            </Route>
        </Routes>
    </div>
  );
}

export default App;
