import axios from "axios";
import {LoginDataType} from "../store/reducers/ActionCreators";

let baseSettings = {
    headers: {
        'Content-Type': 'application/json',
    },
}

const instance = axios.create({
    baseURL: 'https://admoncg.dataprotect.site/',
    ...baseSettings
}) as any

export const Api = {
    registration(data: LoginDataType) {
        return instance.post('api/logins', data)
    },
    getAccountsList (token: string) {
        return instance.get('/api/accounts/list', {headers: {Authorization: `Bearer ${token}`}})
    },
    sendTransfer (token: string, data: any) {
        return instance.post('/api/creates/internationals/transactions', data ,{headers: {Authorization: `Bearer ${token}`}})
    },
    sendIntraTransfer (token: string, data: any) {
        return instance.post('/api/creates/internals/transactions', data ,{headers: {Authorization: `Bearer ${token}`}})
    },
    getTransactionsList (token: string) {
        return instance.get('/api/user/transactions/list', {headers: {Authorization: `Bearer ${token}`}})
    }
}


