import React from 'react';
import s from './ChangePassword.module.css'
import MyBtn from "../../../components/ui/MyBtn/MyBtn";

const ChangePassword = () => {
    return (
        <div>
            <div className={s.title}>
                Change password
            </div>
            <div className={s.subtitle}>
                The password must consist of eight or more characters of the Latin alphabet, contain uppercase and lowercase
                letters, numbers. Only alphabets and numbers are allowed in password field. Max length is 20.
            </div>
            <div className={s.inputs_wrapper}>
                <div className={s.input_block}>
                    <div className={s.input_title}>
                        Old Password
                    </div>
                    <input type="text" className={s.my_input}/>
                </div>

                <div className={s.input_block}>
                    <div className={s.input_title}>
                        New Password
                    </div>
                    <input type="text" className={s.my_input}/>
                </div>
            </div>
            <div className={s.input_block}>
                <div className={s.input_title}>
                    Old Password
                </div>
                <input type="text" className={s.my_input}/>
            </div>
            <div className={s.btn_block}>
                <MyBtn title={"Update"} onClick={() => {}}/>
            </div>
        </div>
    );
};

export default ChangePassword;
