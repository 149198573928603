import React, {useEffect} from 'react';
import s from './Details.module.css'
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import SelectSmall from "../../../components/ui/MySelect/MySelect";
import {GetAccountsThunk} from "../../../store/reducers/ActionCreators";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {accountsArrayType, userDataType} from "../../../store/reducers/UserSlice";
import {useSortedAccounts} from "../../../hooks/useSortedAccounts";

const Details = () => {

    const dispatch = useAppDispatch()

    const userToken: string = useAppSelector( state => state.userReducer.user.userToken)
    const userData:userDataType = useAppSelector( state => state.userReducer.user.userData)
    const userAccountsList:accountsArrayType[] = useAppSelector( state => state.userReducer.userAccounts)

    const [selectedCurrency, setSelectedCurrency] = React.useState("ALL");
    const sortedAccounts = useSortedAccounts(userAccountsList, selectedCurrency)

    const getAccountsListCurrency = () => {
        return userAccountsList?.map((acc) => {
            return acc.currency
        })
    }

    const currencyArr = getAccountsListCurrency()
    currencyArr.push("ALL")


    useEffect(() => {
        dispatch(GetAccountsThunk(userToken))
    }, [])


    return (
        <div className={s.container}>
            <div className={s.title}>
                Client details
            </div>
            <div className={s.client_info}>
                <div className={s.block}>
                    <div className={s.row}>
                        <div className={s.field_name}>Client ID:</div>
                        <div className={s.field_value}>{userData?.clientId}</div>
                    </div>
                    <div className={s.row}>
                        <div className={s.field_name}>Client Name:</div>
                        <div className={s.field_value}>{userData?.firstName} {userData?.lastName}</div>
                    </div>
                </div>
                <div className={s.block}>
                    <div className={s.row}>
                        <div className={s.field_name}>Company Name:</div>
                        <div className={s.field_value}>{userData?.companyName}</div>
                    </div>
                    <div className={s.row}>
                        <div className={s.field_name}>Email Address:</div>
                        <div className={s.field_value}>{userData?.email}</div>
                    </div>
                </div>
            </div>
            <div className={s.accounts}>
                <div className={s.accounts_header}>
                    <div className={s.accounts_title}>
                        Your accounts
                    </div>
                    <MyBtn title={"New Account"}/>
                </div>

                <div className={s.accounts_table}>
                    <div className={s.table_header}>
                        <div className={s.header_item_long}>Account Number</div>
                        <div className={s.header_item_long}>Account Name</div>
                        <div className={s.header_item_short}>
                            <span className={s.currency}>Currency</span>
                            <SelectSmall selectedItem={selectedCurrency}
                                         setSelectedItem={setSelectedCurrency}
                                         item={currencyArr}
                                         width={90}
                                         height={40}
                            />
                        </div>
                        <div className={s.header_item_short}>Balance</div>
                        <div className={s.header_item_short}>Security Deposit</div>
                        <div className={s.header_item_status}>Status</div>
                    </div>
                    {
                        sortedAccounts.map((acc: any) => <div className={s.table_row} key={acc.number}>
                            <div className={s.header_item_long}>{acc.number}</div>
                            <div className={s.header_item_long}>{acc.type}</div>
                            <div className={s.header_item_short}>
                                {acc.currency}
                            </div>
                            <div className={s.header_item_short}>{acc.balance}</div>
                            <div className={s.header_item_short}>{acc.minBalance}</div>
                            <div className={s.header_item_status}>{acc.isActive ? "Active" : "Inactive"}</div>
                        </div>)
                    }

                </div>
            </div>
        </div>
    );
};

export default Details;
