import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import s from './MySelect.module.css'
import {Dispatch, SetStateAction} from "react";

type propsType = {
    item?: (string | null)[]
    width: number
    height: number
    setSelectedItem?: Dispatch<SetStateAction<string>>
    selectedItem?: string

}

export default function SelectSmall(props: propsType) {


    const handleChange = (event: SelectChangeEvent) => {
        props.setSelectedItem?.(event.target.value);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: props.width, height: props.height }}  size="small" className={s.my_select_wrapper}>
            <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={props.selectedItem}
                onChange={handleChange}
                className={s.my_select}
            >
                {
                    props.item?.map((i: any) => <MenuItem key={i} value={i}>{i}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}
