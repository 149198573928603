import React, {useState} from 'react';
import s from "./Exchange.module.css";
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {sendIntraTransfer} from "../../../store/reducers/ActionCreators";
import MySelectAccounts from "../../../components/ui/MySelectAccounts/MySelectAccounts";
import {useAccountsForSelect} from "../../../hooks/useAccountsForSelect";

const Exchange = () => {

    const dispatch = useAppDispatch()
    const userToken: string = useAppSelector( state => state.userReducer.user.userToken)
    const [selectedAccountNumberTo, setSelectedAccountNumberTo] = useState("");
    const [selectedAccountNumberFrom, setSelectedAccountNumberFrom] = useState("");
    const [selectedAccountCurrencyTo, setSelectedAccountCurrencyTo] = useState("All");
    const [selectedAccountCurrencyFrom, setSelectedAccountCurrencyFrom] = useState("All");
    const [checkedTerms, setCheckedTerms] = useState<boolean>(false);
    const [checkedError, setCheckedError] = useState<boolean>(false);
    const [dataForIntraTransfer, setDataForIntraTransfer] = useState<any>({
        fromAccount: "",
        toAccount: "",
        amount: undefined,
        reference: "Exchange"
    });
    const accountsForSelect = useAccountsForSelect()

    const onAccountSelectFromHandler = (number: string, currency: string) => {
        setSelectedAccountCurrencyFrom(currency)
        setSelectedAccountNumberFrom(number)
        setDataForIntraTransfer({...dataForIntraTransfer, fromAccount: number})
    }

    const onAccountSelectToHandler = (number: string, currency: string) => {
        setSelectedAccountCurrencyTo(currency)
        setSelectedAccountNumberTo(number)
        setDataForIntraTransfer({...dataForIntraTransfer, toAccount: number})
    }

    const sendDataForIntraTransaction = async () => {
        if (!checkedTerms) {
            setCheckedError(true)
            return
        }
        console.log("sfes")
        if (dataForIntraTransfer.fromAccount.length === 0 || dataForIntraTransfer.toAccount.length === 0 ||  dataForIntraTransfer.amount.length === 0) {
            return
        }

        await dispatch(sendIntraTransfer(userToken, dataForIntraTransfer))
    }

    return (
        <div>
            <div className={s.inputs_wrapper}>
                <div className={s.input_block}>
                    <div className={s.input_title}>
                        From Account
                    </div>
                    <MySelectAccounts item={accountsForSelect}
                                      width={376}
                                      height={48}
                                      onAccountSelectHandler={onAccountSelectFromHandler}
                                      selectedItem={selectedAccountNumberFrom}
                    />
                </div>

                <div className={s.input_block}>
                    <div className={s.input_title}>
                        Currency
                    </div>
                    <div className={s.current_currency}>
                        {selectedAccountCurrencyFrom}
                    </div>
                </div>
            </div>
            <div className={s.inputs_wrapper}>
                <div className={s.input_block}>
                    <div className={s.input_title}>
                        To Account
                    </div>
                    <MySelectAccounts item={accountsForSelect}
                                      width={376}
                                      height={48}
                                      onAccountSelectHandler={onAccountSelectToHandler}
                                      selectedItem={selectedAccountNumberTo}
                    />
                </div>

                <div className={s.input_block}>
                    <div className={s.input_title}>
                        Currency
                    </div>
                    <div className={s.current_currency}>
                        {selectedAccountCurrencyTo}
                    </div>
                </div>
            </div>
            <div className={s.inputs_wrapper}>
                <div className={s.input_block}>
                    <div className={s.input_title}>
                        Amount
                    </div>
                    <input
                        value={dataForIntraTransfer.amount}
                        onChange={(e) => {setDataForIntraTransfer({...dataForIntraTransfer, amount: e.target.value})}}
                        type="text"
                        className={s.my_input}
                    />
                </div>
            </div>
            <div className={s.terms_block}>
                <input type="checkbox"
                       checked={checkedTerms}
                       onChange={() => {setCheckedTerms(!checkedTerms); setCheckedError(false)}}
                />
                <span className={checkedError ? s.checked_error : ''}>I agree with CG Bank</span>
                <span className={s.yellow}>
                    Terms and Conditions
                </span>
            </div>
            <MyBtn title={"Transfer"} onClick={sendDataForIntraTransaction}/>
        </div>
    );
};

export default Exchange;
