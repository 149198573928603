import React from 'react'
import s from './MyPopupTransactionSuccess.module.css'
import {useNavigate} from "react-router-dom";
import {STATEMENT} from "../../../router/Constants";
import {userSlice} from "../../../store/reducers/UserSlice";
import {useAppDispatch} from "../../../hooks/redux";

const MyPopupTransactionSuccess = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const closePopup = () => {
        dispatch(userSlice.actions.setTransactionWasSendSuccess(null))
    }

    const closeAndRedirect = () => {
        dispatch(userSlice.actions.setTransactionWasSendSuccess(null))
        navigate(STATEMENT)
    }

    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <div className={s.title}>
                    Your request has been sent.
                    Check the status of the transaction?
                </div>
                <div className={s.btns_block}>
                    <button className={s.my_btn_no} onClick={closePopup}>No</button>
                    <button className={s.my_btn_yes} onClick={closeAndRedirect}>Yes</button>
                </div>
            </div>
        </div>
    );
};

export default MyPopupTransactionSuccess;
