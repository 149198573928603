import React from 'react'
import s from './MyPopupTransactionRejected.module.css'
import {useNavigate} from "react-router-dom";
import {userSlice} from "../../../store/reducers/UserSlice";
import {useAppDispatch} from "../../../hooks/redux";
import closeIcon from "../../../assets/img/close.png"

const MyPopupTransactionRejected = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const closePopup = () => {
        dispatch(userSlice.actions.setTransactionWasSendSuccess(null))
    }

    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <div className={s.close}>
                    <img src={closeIcon} onClick={closePopup} alt=""/>
                </div>
                <div className={s.title}>
                    Your request was rejected. <br/>
                    Please try again.
                </div>
            </div>
        </div>
    );
};

export default MyPopupTransactionRejected;
