import React, {useState} from 'react';
import s from './Transfers.module.css'
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import MySelectAccounts from "../../../components/ui/MySelectAccounts/MySelectAccounts";
import {SendTransferThunk} from "../../../store/reducers/ActionCreators";
import {useAccountsForSelect} from "../../../hooks/useAccountsForSelect";

const Transfers = () => {

    const dispatch = useAppDispatch()
    const userToken: string = useAppSelector( state => state.userReducer.user.userToken)
    const [selectedAccountNumber, setSelectedAccountNumber] = useState("");
    const [checkedTerms, setCheckedTerms] = useState<boolean>(false);
    const [checkedError, setCheckedError] = useState<boolean>(false);
    const [selectedAccountCurrency, setSelectedAccountCurrency] = useState("All");
    const [dataForTransaction, setDataForTransaction] = useState<any>({
        fromAccount: "",
        amount: undefined,
        reference: "",
        bankName: "",
        bankAddress: "",
        beneficiaryName: "",
        beneficiaryCountry: "",
        beneficiaryCity: "",
        beneficiaryAddress: "",
        swiftCode: "",
        ibanCode: ""
    });
    const accountsForSelect = useAccountsForSelect()

    const onAccountSelectHandler = (number: string, currency: string) => {
        setSelectedAccountCurrency(currency)
        setSelectedAccountNumber(number)
        setDataForTransaction({...dataForTransaction, fromAccount: number})
    }

    const sendDataForInternationalTransaction = async () => {
        if (!checkedTerms) {
            setCheckedError(true)
            return
        }

        if (dataForTransaction.ibanCode.length === 0 || dataForTransaction.swiftCode.length === 0) {
            return
        }
        await dispatch(SendTransferThunk(userToken, dataForTransaction))
    }

    return (
        <div className={s.wrapper}>
            <div className={s.title}>
                Payment information
            </div>
            <div className={s.inputs_wrapper}>
                <div className={s.input_block}>
                    <div className={s.input_title}>
                        Account Number
                    </div>
                    <MySelectAccounts item={accountsForSelect}
                                      width={376}
                                      height={48}
                                      onAccountSelectHandler={onAccountSelectHandler}
                                      selectedItem={selectedAccountNumber}
                    />
                </div>

                <div className={s.input_block}>
                    <div className={s.input_title}>
                        Currency
                    </div>
                    <div className={s.current_currency}>
                        {selectedAccountCurrency}
                    </div>
                </div>
            </div>
            <div className={s.input_block}>
                <div className={s.input_title}>
                    Amount
                </div>
                <input value={dataForTransaction.amount}
                       onChange={(e) => setDataForTransaction({...dataForTransaction, amount: e.target.value})}
                       type="text"
                       className={s.my_input}
                />
            </div>
            <div className={s.input_block}>
                <div className={s.input_title}>
                    Bank Name
                </div>
                <input
                    value={dataForTransaction.bankName}
                    onChange={(e) => setDataForTransaction({...dataForTransaction, bankName: e.target.value})}
                    type="text"
                    className={s.my_input_long}
                />
            </div>
            <div className={s.input_block}>
                <div className={s.input_title}>
                    Bank Address
                </div>
                <input
                    value={dataForTransaction.bankAddress}
                    onChange={(e) => setDataForTransaction({...dataForTransaction, bankAddress: e.target.value})}
                    type="text"
                    className={s.my_input_long}
                />
            </div>
            <div className={s.inputs_wrapper}>
                <div className={s.input_block}>
                    <div className={s.input_title}>
                        SWIFT Code
                    </div>
                    <input
                        type="text"
                        className={s.my_input}
                        value={dataForTransaction.swiftCode}
                        onChange={(e) => setDataForTransaction({...dataForTransaction, swiftCode: e.target.value})}
                    />
                </div>

                <div className={s.input_block}>
                    <div className={s.input_title}>
                        IBAN
                    </div>
                    <input
                        value={dataForTransaction.ibanCode}
                        onChange={(e) => setDataForTransaction({...dataForTransaction, ibanCode: e.target.value})}
                        type="text"
                        className={s.my_input}
                    />
                </div>
            </div>
            <div className={s.inputs_wrapper}>
                <div className={s.input_block}>
                    <div className={s.input_title}>
                        Beneficiary Name
                    </div>
                    <input
                        value={dataForTransaction.beneficiaryName}
                        onChange={(e) => setDataForTransaction({...dataForTransaction, beneficiaryName: e.target.value})}
                        type="text"
                        className={s.my_input_long}
                    />
                </div>

                {/*<div className={s.input_block}>*/}
                {/*    <div className={s.input_title}>*/}
                {/*        Beneficiary Account Number*/}
                {/*    </div>*/}
                {/*    <input*/}
                {/*        value={""}*/}
                {/*        onChange={(e) => setDataForTransaction({...dataForTransaction, beneficiaryName: e.target.value})}*/}
                {/*        type="text"*/}
                {/*        className={s.my_input}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
            <div className={s.inputs_wrapper}>
                <div className={s.input_block}>
                    <div className={s.input_title}>
                        Beneficiary Country
                    </div>
                    <input
                        value={dataForTransaction.beneficiaryCountry}
                        onChange={(e) => setDataForTransaction({...dataForTransaction, beneficiaryCountry: e.target.value})}
                        type="text"
                        className={s.my_input}
                    />
                </div>

                <div className={s.input_block}>
                    <div className={s.input_title}>
                        Beneficiary City
                    </div>
                    <input
                        value={dataForTransaction.beneficiaryCity}
                        onChange={(e) => setDataForTransaction({...dataForTransaction, beneficiaryCity: e.target.value})}
                        type="text"
                        className={s.my_input}
                    />
                </div>
            </div>
            <div className={s.input_block}>
                <div className={s.input_title}>
                    Beneficiary Address
                </div>
                <input
                    value={dataForTransaction.beneficiaryAddress}
                    onChange={(e) => setDataForTransaction({...dataForTransaction, beneficiaryAddress: e.target.value})}
                    type="text"
                    className={s.my_input_long}
                />
            </div>
            <div className={s.input_block}>
                <div className={s.input_title}>
                    Purpose
                </div>
                <textarea
                    value={dataForTransaction.reference}
                    onChange={(e) => setDataForTransaction({...dataForTransaction, reference: e.target.value})}
                    className={s.my_textarea}
                />
            </div>
            <div className={s.terms_block}>
                <input type="checkbox"
                       checked={checkedTerms}
                       onChange={() => {setCheckedTerms(!checkedTerms); setCheckedError(false)}}
                />
                <span className={checkedError ? s.checked_error : ''}>I agree with CG Bank</span>
                <span className={s.yellow}>
                    Terms and Conditions
                </span>
            </div>
            <MyBtn title={"Transfer"} onClick={sendDataForInternationalTransaction}/>
        </div>
    );
};

export default Transfers;
