import {createSlice, PayloadAction} from "@reduxjs/toolkit";


type UserState = {
    accounts: any;
    error: any,
    user: userType,
    userAccounts: accountsArrayType[],
    userTransactions: Array<any>
    transactionWasSendSuccess: boolean | null
}

type userType = {
    userToken: string,
    userData: userDataType,
}

export type userDataType = {
    address: string | null
    callPhone: string | null
    city: string | null
    clientId: string | null
    companyName: string | null
    country: string | null
    email: string | null
    fax: string | null
    firstName: string | null
    lastName: string | null
    phoneNumber: string | null
    salutation: string | null
    stateOrProvince: string | null
}

export type accountsArrayType = {
    balance: string | null
    currency: string | null
    isActive: boolean | null
    minBalance: string | null
    number: string | null
    type: string | null
}


const initialState: UserState = {
    accounts: [],
    error: null,
    user: {
        userToken: "",
        userData: {
            address: "",
            callPhone: "",
            city: "",
            clientId: "",
            companyName: "",
            country: "",
            email: "",
            fax: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            salutation: "",
            stateOrProvince: "",
        },
    },
    userAccounts: [],
    userTransactions: [],
    transactionWasSendSuccess: null
}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userAuthorizationSuccess(state, action) {
            state.user = action.payload
            state.error = null
        },
        userAuthorizationError(state, action: PayloadAction<string>) {
            state.error = action.payload
        },
        signOut(state) {
            state.user.userToken = ""
            state.user.userData = {
                address: null,
                callPhone: null,
                city: null,
                clientId: null,
                companyName: null,
                country: null,
                email: null,
                fax: null,
                firstName: null,
                lastName: null,
                phoneNumber: null,
                salutation: null,
                stateOrProvince: null,
            }
            state.userAccounts = []
            state.error = null
            state.userTransactions = []
        },
        setUserAccounts(state, action) {
            state.userAccounts = action.payload
        },
        setUserTransactions(state, action) {
            state.userTransactions = action.payload
        },
        setTransactionWasSendSuccess (state, action) {
            state.transactionWasSendSuccess = action.payload
        }
    }
})

export default userSlice.reducer
