import {AppDispatch} from "../store";
import {Api} from "../../api/Api";
import {userSlice} from "./UserSlice";

export type LoginDataType = {
    login: string,
    password: string,
}

export const LoginThunk = (data: LoginDataType, props?: any) => async (dispatch: AppDispatch) => {
    try {
        const response = await Api.registration(data)
        await dispatch(userSlice.actions.userAuthorizationError(response.status))
        await dispatch(userSlice.actions.userAuthorizationSuccess(response.data))
    } catch (e: any) {
        await dispatch(userSlice.actions.userAuthorizationError(e.response.status))
    }
}

export const LogoutThunk = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(userSlice.actions.signOut())
    } catch (e: any) {
        console.log(e)
    }
}

export const GetAccountsThunk = (token: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await Api.getAccountsList(token)
        dispatch(userSlice.actions.setUserAccounts(response.data))
    } catch (e: any) {
        console.log(e)
    }
}

export const GetTransactionsListThunk = (token: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await Api.getTransactionsList(token)
        dispatch(userSlice.actions.setUserTransactions(response.data))
    } catch (e: any) {
        console.log(e)
    }
}

export const SendTransferThunk = (token: string, dataForTransaction: any) => async (dispatch: AppDispatch) => {
    try {
        await Api.sendTransfer(token, dataForTransaction)
        dispatch(userSlice.actions.setTransactionWasSendSuccess(true))
    } catch (e) {
        console.log(e)
        dispatch(userSlice.actions.setTransactionWasSendSuccess(false))
    }
}

export const sendIntraTransfer = (token: string, dataForTransaction: any) => async (dispatch: AppDispatch) => {
    try {
        await Api.sendIntraTransfer(token, dataForTransaction)
        dispatch(userSlice.actions.setTransactionWasSendSuccess(true))
    } catch (e) {
        console.log(e)
        dispatch(userSlice.actions.setTransactionWasSendSuccess(false))
    }
}
